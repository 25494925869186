<template>
  <div>
    <post-term-wrapper
      v-for="(wPosts, idx) in wraperPosts"
      :key="idx"
      :posts="wPosts"
    />
    <div class="button-module" v-show="enabled">
      <a @click="getPost" class="btn">VER MÁS <i class="icon-arrow"></i></a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PostTermWrapper from "../../post-components/post-term-wrapper/PostTermWrapper.vue";
export default {
  components: { PostTermWrapper },
  props: {
    term: String,
    initOffset: Number,
    total: Number,
  },
  created: function () {
    this.take = 10;
    this.offset = this.initOffset;
  },
  methods: {
    getPost: function () {
      this.loading = true;
      var _self = this;
      axios
        .get("/api/pager/term", {
          params: {
            term: this.term,
            take: this.take,
            offset: this.offset,
          },
        })
        .then(function (d) {
          _self.loading = false;
          _self.posts = _self.posts.concat(d.data);
          _self.offset += _self.take;
        });
    },
  },
  computed: {
    enabled() {
      return this.offset < this.total && !this.loading;
    },
    wraperPosts() {
      return this.posts.reduce((acc, q, idx) => {
        const p = Math.floor(idx / 5);
        (acc[p] = acc[p] || []).push(q);
        return acc;
      }, []);
    },
  },
  data() {
    return {
      posts: [],
      loading: false,
    };
  },
};
</script>
