<template>
  <div>
    <div class="row row-cols-1 row-cols-md-2">
      <div class="col" v-for="(post, idx) in posts.slice(0, 4)" :key="post.id">
        <post-card-5
          :post="post"
          :groupSize="groupSize"
          @tf-update="tfUpd(idx, $event)"
        />
      </div>
    </div>
    <post-card-2 :post="posts.slice(4, 5)[0]" />
  </div>
</template>

<script>
import PostCard2 from "../post-card-2/PostCard2.vue";
import PostCard5 from "../post-card-5/PostCard5.vue";
export default {
  components: {
    PostCard2,
    PostCard5,
  },
  created() {
    this.tfNews = {};
    this.offset = this.initOffset;
    this.familyName = "pag";
  },
  props: {
    posts: Array,
  },
  methods: {
    tfUpd: function (y, z) {
      const a = this.tfNews;
      if (!a[this.familyName]) {
        a[this.familyName] = [];
      }
      a[this.familyName][y] = z;
      this.groupSize = Math.min.apply(Math, a[this.familyName]);
      this.tfNews = Object.assign({}, this.tfNews, a);
    },
  },
  data() {
    return {
      groupSize: 38,
    };
  },
};
</script>