import {
    initMain
} from './vue/controllers/main';
import {
    header
} from './vue/controllers/header';
import lazyload from './features/lazy-load';
import {
    TextFill,
    tfmixin
} from './vue/components/text-fill/TextFill.vue';

import TermPager from './vue/components/term-pager/TermPager.vue';

import VueTinySlider from "vue-tiny-slider";
lazyload();
initMain([tfmixin], {
    TextFill,
    TermPager,
    VueTinySlider
});
header();