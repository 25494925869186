var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.wraperPosts, function (wPosts, idx) {
        return _c("post-term-wrapper", { key: idx, attrs: { posts: wPosts } })
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.enabled,
              expression: "enabled",
            },
          ],
          staticClass: "button-module",
        },
        [
          _c("a", { staticClass: "btn", on: { click: _vm.getPost } }, [
            _vm._v("VER MÁS "),
            _c("i", { staticClass: "icon-arrow" }),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }