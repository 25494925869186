var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.post
    ? _c(
        "div",
        { staticClass: "news-card-5 row", class: _vm.post.sectionClassName },
        [
          _c(
            "a",
            {
              staticClass: "col-5 col-md-12 p-md-1",
              attrs: { href: _vm.post.postUrl },
            },
            [
              _c("img", {
                staticClass: "lazyload",
                attrs: {
                  "data-src": _vm.post.imageUrl,
                  src: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E",
                  alt: _vm.post.imageAlt,
                  title: _vm.post.imageTitle,
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col col-md-12 info" },
            [
              _c("div", { staticClass: "brand" }, [
                _c("a", { attrs: { href: _vm.post.newspaperUrl } }, [
                  _c("span", { staticClass: "cat-dot" }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "cat-title",
                    domProps: { innerHTML: _vm._s(_vm.post.newspaper) },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "text-fill",
                {
                  attrs: { "group-size": _vm.groupSize, "max-font-size": 38 },
                  on: {
                    "new-font-size": function ($event) {
                      return _vm.$emit("tf-update", $event)
                    },
                  },
                },
                [
                  _c("a", {
                    attrs: { href: _vm.post.postUrl },
                    domProps: { innerHTML: _vm._s(_vm.post.title) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("span", {
                staticClass: "date",
                domProps: { innerHTML: _vm._s(_vm.postDate) },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }