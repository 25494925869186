var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row row-cols-1 row-cols-md-2" },
        _vm._l(_vm.posts.slice(0, 4), function (post, idx) {
          return _c(
            "div",
            { key: post.id, staticClass: "col" },
            [
              _c("post-card-5", {
                attrs: { post: post, groupSize: _vm.groupSize },
                on: {
                  "tf-update": function ($event) {
                    return _vm.tfUpd(idx, $event)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("post-card-2", { attrs: { post: _vm.posts.slice(4, 5)[0] } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }