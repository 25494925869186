<template>
  <div class="news-card-5 row" :class="post.sectionClassName" v-if="post">
    <a :href="post.postUrl" class="col-5 col-md-12 p-md-1">
      <img
        :data-src="post.imageUrl"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E"
        class="lazyload"
        :alt="post.imageAlt"
        :title="post.imageTitle"
      />
    </a>
    <div class="col col-md-12 info">
      <div class="brand">
        <a :href="post.newspaperUrl">
          <span class="cat-dot"></span>
          <span class="cat-title" v-html="post.newspaper"></span>
        </a>
      </div>
      <text-fill
        v-cloak
        :group-size="groupSize"
        :max-font-size="38"
        v-on:new-font-size="$emit('tf-update', $event)"
      >
        <a :href="post.postUrl" v-html="post.title"></a>
      </text-fill>
      <span class="date" v-html="postDate"></span>
    </div>
  </div>
</template>

<script>
import { TextFill } from "../../components/text-fill/TextFill.vue";
import moment from "moment";
import { execute } from "../../../features/lazy-load";
export default {
  components: { TextFill },
  updated() {
    execute();
  },
  props: {
    post: Object,
    groupSize: Number,
  },
  computed: {
    postDate: function () {
      return moment(this.post.create).format("DD/MM/YYYY");
    },
  },
};
</script>
